import React, { useEffect, useState } from 'react';
import { Button, Card, CardActions, CardContent, Container, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import { useHistory } from 'react-router-dom';

import ic_call from '../../../assets/Icons/ic_call.svg';
import ic_message from '../../../assets/Icons/ic_message.svg';
import ic_need_help from '../../../assets/Icons/ic_need_help.svg';
import useStyles from './NeedHeplDialogStyles';

const NeedHeplDialog: React.FC = () => {
    const classes = useStyles();
    const history = useHistory();

    const [callOpen, setCallOpen] = useState(false);
    const [chatOpen, setChatOpen] = useState(false);

    const handleCallClick = () => {
        window.location.href = 'tel:+919872442663';
    };

    const onClickMail = () => {
        window.location.href = 'mailto:support@atyourservice.homes';
    };
    const onCallClick = () => {
        window.location.href = 'tel:+918080720001';
    };
    const onCallClick2 = () => {
        setChatOpen(false);
        window.location.href = 'tel:+918390323002';
    };

    const goToNeedHelp = () => {
        setChatOpen(true);
    };

    return (
        <div className={classes.centerScreen}>
            <img onClick={goToNeedHelp} src={ic_need_help} style={{ cursor: 'pointer' }} alt="need help" />
            <Dialog
                open={chatOpen}
                onClose={() => setChatOpen(false)}
                aria-labelledby="patient-ahead-dialog-title"
                PaperProps={{
                    style: {
                        minWidth: '400px',
                        minHeight: '300px',
                    },
                }}
            >
                <DialogTitle id="patient-ahead-dialog-title">
                    <Typography variant="h6">
                        Need Help
                    </Typography>
                    <IconButton
                        aria-label="close"
                        onClick={() => setChatOpen(false)}
                        style={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <div onClick={onCallClick} style={{ cursor: 'pointer' }}>
                        <Grid container className={classes.paymentCard} spacing={1}>
                            <Grid item >
                                <img
                                    style={{ height: '40px' }}
                                    src={ic_call}
                                    alt="call"
                                />
                            </Grid>
                            <Grid item>
                                <Typography style={{ color: '#000000', fontSize: '12px', padding: '0px', marginLeft: 10 }}>8080720001</Typography>
                            </Grid>
                        </Grid>
                    </div>
                    <div onClick={onCallClick} style={{ cursor: 'pointer' }}>
                        <Grid container className={classes.paymentCard} spacing={1}>
                            <Grid item >
                                <img
                                    style={{ height: '40px' }}
                                    src={ic_call}
                                    alt="call"
                                />
                            </Grid>
                            <Grid item>
                                <Typography style={{ color: '#000000', fontSize: '12px', padding: '0px', marginLeft: 10 }}>8390323002</Typography>
                            </Grid>
                        </Grid>
                    </div>
                    <div onClick={onClickMail} style={{ cursor: 'pointer' }}>
                        <Grid container className={classes.paymentCard} spacing={1}>
                            <Grid item >
                                <img
                                    style={{ height: '40px' }}
                                    src={ic_message}
                                    alt="mail"
                                />
                            </Grid>
                            <Grid item>
                                <Typography style={{ color: '#000000', fontSize: '12px', padding: '0px', marginLeft: 10 }}>support@atyourservice.homes</Typography>
                            </Grid>
                        </Grid>
                    </div>
                </DialogContent>
                <DialogActions style={{ justifyContent: 'center', paddingLeft: '20px', paddingRight: '20px' }}>
                </DialogActions>
            </Dialog>
        </div>
    );
};
export default NeedHeplDialog;
