import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';

import ic_call from '../../../assets/Icons/ic_call.svg';
import ic_message from '../../../assets/Icons/ic_message.svg';
import useStyles from './NeedHeplDialogStyles';

interface NeedHeplDialogProps {
    open: boolean;
    onClose: () => void;
}

const NeedHeplDialogHome: React.FC<NeedHeplDialogProps> = ({ open, onClose }) => {
    const classes = useStyles();

    const handleCallClick = () => {
        window.location.href = 'tel:+919872442663';
    };

    const onClickMail = () => {
        window.location.href = 'mailto:support@atyourservice.homes';
    };

    const onCallClick = () => {
        window.location.href = 'tel:+918080720001';
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="need-help-dialog-title"
            PaperProps={{
                style: {
                    minWidth: '400px',
                    minHeight: '300px',
                },
            }}
        >
            <DialogTitle id="patient-ahead-dialog-title">
                <Typography variant="h6">
                    Need Help
                </Typography>
                <IconButton
                    aria-label="close"
                    onClick={() => onClose()}
                    style={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <div onClick={onCallClick} style={{ cursor: 'pointer' }}>
                    <Grid container className={classes.paymentCard} spacing={1}>
                        <Grid item >
                            <img
                                style={{ height: '40px' }}
                                src={ic_call}
                                alt="call"
                            />
                        </Grid>
                        <Grid item>
                            <Typography style={{ color: '#000000', fontSize: '12px', padding: '0px', marginLeft: 10 }}>8080720001</Typography>
                        </Grid>
                    </Grid>
                </div>
                <div onClick={onCallClick} style={{ cursor: 'pointer' }}>
                    <Grid container className={classes.paymentCard} spacing={1}>
                        <Grid item >
                            <img
                                style={{ height: '40px' }}
                                src={ic_call}
                                alt="call"
                            />
                        </Grid>
                        <Grid item>
                            <Typography style={{ color: '#000000', fontSize: '12px', padding: '0px', marginLeft: 10 }}>8390323002</Typography>
                        </Grid>
                    </Grid>
                </div>
                <div onClick={onClickMail} style={{ cursor: 'pointer' }}>
                    <Grid container className={classes.paymentCard} spacing={1}>
                        <Grid item >
                            <img
                                style={{ height: '40px' }}
                                src={ic_message}
                                alt="mail"
                            />
                        </Grid>
                        <Grid item>
                            <Typography style={{ color: '#000000', fontSize: '12px', padding: '0px', marginLeft: 10 }}>support@atyourservice.homes</Typography>
                        </Grid>
                    </Grid>
                </div>
            </DialogContent>
            <DialogActions style={{ justifyContent: 'center', paddingLeft: '20px', paddingRight: '20px' }}>
            </DialogActions>
        </Dialog>
    );
};

export default NeedHeplDialogHome;
